<script>
import agreementTitle from '@/lib/agreement-title';
// import matterDetails from './matterDetail.json';
import cloneDeep from 'lodash/cloneDeep';
import base from '@/lib/agreement/base.json';
import { analytics, firebaseApp  } from '././firebase';
import { BSpinner } from 'bootstrap-vue'
// import MatterDetailsVue from './components/Agreements/MatterDetails.vue';
// import axios from 'axios';

export default {
	name: 'ClioImport',
	components: {
		BSpinner,
	},
	data() {
		return {
            selectedMatterId: '',
			primaryParent: '',
			secondaryContactId:'',
			matterDetails: '',
            agreementData: cloneDeep(base),
			partner1Details:'',
			partner2Details:'',
			loading: false,
			hasSecondaryContact: false,
			partner1:'',
			partner2:'',
			contactData:'',
			partner: '',
			matterList:[],
			secondaryContactList:[],
			selectedMatter:{},
			loadingMatter:false,
			loadingSpouse:false
		};
	},
	props:['showClioModal', 'hideClioModal', 'saveDetails','title'],
	computed: {

		partnerOptions(){
			this.partner1 = this.selectedMatter.data.name;
			this.partner2 = this.secondaryContactList.find((contact)=> { return contact.value == this.secondaryContactId}).text;
			return [
				{ text: `${this.partner1}`, value: 'partner1' },
				{ text: `${this.partner2}`, value: 'partner2' },
			];
			
		},
	},
	methods: {
        agreementTitle,
		matterOptions(){
			this.matterList= [];
			if(this.matterDetails && this.matterDetails.data){
				this.matterDetails.data.forEach(matter=>{
					const matterText = `${matter.display_number}: ${matter.description}`
					this.matterList.push({value:matter.id, text: matterText , fullData: matter})
				})
			}
		},
		onMatterSelect(){
			if(this.selectedMatterId && this.matterList){
				for (let index = 0; index < this.matterList.length; index++) {
					if(this.selectedMatterId==this.matterList[index].value){
						if(this.matterList[index].fullData.relationships && this.matterList[index].fullData.relationships.length){
							this.hasSecondaryContact=true;
							this.loadingSpouse=true;
							this.clioGetRelatedContacts();
						}else{
							this.hasSecondaryContact=false;
						}
						this.clioClientLink();
						break;
					}else{
						this.hasSecondaryContact=false;
					}
				}
			}else{
				this.hasSecondaryContact=false;
			}
		},
		setAdditionalDataOnMatterSelect(){
			if(this.primaryParent){
				if(this.primaryParent=='partner1'){
					this.partner1Details=this.selectedMatter.data;
					this.partner2Details=this.secondaryContactList.find((contact)=> contact.value == this.secondaryContactId ).fullData;
				}else{
					this.partner1Details=this.secondaryContactList.find((contact)=> contact.value == this.secondaryContactId ).fullData;
					this.partner2Details=this.selectedMatter.data;
				}
			}else{
				this.partner1Details=this.selectedMatter.data;
			}
			// console.log("Success")
				// Primary Parent details
			if(this.partner1Details){
				this.agreementData.partner1.name.first=this.partner1Details.first_name;
				this.agreementData.partner1.name.middle=this.partner1Details.middle_name;
				this.agreementData.partner1.name.last=this.partner1Details.last_name;
				this.agreementData.partner1.birthdate=this.partner1Details.date_of_birth;
				this.agreementData.partner1.mail=this.partner1Details.primary_email_address;
				if(this.partner1Details.addresses && this.partner1Details.addresses.length){
					this.agreementData.partner1.address.street=this.partner1Details.addresses[0].street;
					this.agreementData.partner1.address.city=this.partner1Details.addresses[0].city;
					this.agreementData.partner1.address.province=this.partner1Details.addresses[0].province;
					this.agreementData.partner1.address.postalCode=this.partner1Details.addresses[0].postal_code;
					this.agreementData.partner1.address.country=this.partner1Details.addresses[0].street;
				}
			}
				// Secondary Parent details
				if(this.primaryParent)
				{
					this.agreementData.partner2.name.first=this.partner2Details.first_name;
					this.agreementData.partner2.name.middle=this.partner2Details.middle_name;
					this.agreementData.partner2.name.last=this.partner2Details.last_name;
					this.agreementData.partner2.birthdate=this.partner2Details.date_of_birth;
					this.agreementData.partner2.mail=this.partner2Details.primary_email_address;
					if(this.partner2Details.addresses && this.partner2Details.addresses.length){
						this.agreementData.partner2.address.street=this.partner2Details.addresses[0].street;
						this.agreementData.partner2.address.city=this.partner2Details.addresses[0].city;
						this.agreementData.partner2.address.province=this.partner2Details.addresses[0].province;
						this.agreementData.partner2.address.postalCode=this.partner2Details.addresses[0].postal_code;
						this.agreementData.partner2.address.country=this.partner2Details.addresses[0].country;
					}
				}
		},
        createAgreement() {
			try{
				this.$emit('isLoading', "true");
				// // console.log("clio: ",this.selectedMatterId)
					if(this.selectedMatterId){
						this.setAdditionalDataOnMatterSelect()
					}
				this.loading = true;
				this.$store
					.dispatch('createAgreement', {
						...this.agreementData,
						title: agreementTitle(this.agreementData),
					})
					.then(({ id, error }) => {
						if (error) {
							this.error = 'Something unexpected went wrong.';
						} else if (id) {
							analytics.logEvent('agreement_created');
							this.$router.push({
								name: 'agreement',
								params: { id },
							});
						}
					})
					.catch((err)=>{
						console.error("createAgreement error->",err)
					});
			}catch(error){
				console.error("Error in createAgreement:", error)
			}
		},
		async clioMatterDetailsLink(){
			try
			{
				this.loadingMatter = true;
				const clioMatterDetails = firebaseApp.functions('northamerica-northeast1').httpsCallable('clioApis-matterDetails');
				const accessTokenClio=JSON.parse(localStorage.getItem('cliotoken'))
				// // console.log("acces TOKEN CLIO MATTER:",accessTokenClio.access_token)
				let header={
					authorization: accessTokenClio.access_token
				}
				await clioMatterDetails(header)
				.then(linkResponse=>{
					if(linkResponse){
						// // console.log('clioMatterDetailsLink ->', JSON.parse(linkResponse.data.data))
						if(linkResponse.data && linkResponse.data){
							this.matterDetails = JSON.parse(linkResponse.data.data);
							this.loadingMatter = false;
							this.matterOptions()	
						}
						// // console.log("link response Matters:", linkResponse)
					}
				})
				.catch((err)=>{
					console.error("clioMatterDetailsLink error->",err)
				})
				// // console.log("link1:", linkResponse1)
				
			}catch(error){
				// console.log("clioMatterDetailsLink error:",error)
			}
		},
		async clioGetRelatedContacts(){
			try
			{
				const clioRelatedContacts = firebaseApp.functions('northamerica-northeast1').httpsCallable('clioApis-relatedContacts');
				const accessTokenClio=JSON.parse(localStorage.getItem('cliotoken'))
				if(!accessTokenClio || !accessTokenClio.access_token){
					return console.error("No access token")
				} 
				if(!this.selectedMatterId){
					return console.error("No matter Id present")
				}
				let body={
					authorization: accessTokenClio.access_token,
					matterId: this.selectedMatterId
				}
				clioRelatedContacts(body)
						.then(relContactResponse=>{
							if(relContactResponse){
								if(relContactResponse.data && relContactResponse.data && relContactResponse.data.data && JSON.parse(relContactResponse.data.data)){
								// // console.log('clioRelatedContacts resp->', JSON.parse(relContactResponse.data.data))
									let relatedContactList = JSON.parse(relContactResponse.data.data);
									if(relatedContactList && relatedContactList.data && relatedContactList.data.length){
										relatedContactList.data.forEach(element => {
											// this.partner2 = `${element.first_name} ${element.last_name}`
											let contactText = `${element.name}`
											this.secondaryContactList.push({value:`${element.id}`, text: contactText, fullData: element})
										});
									}
									this.loadingSpouse = false;
								}
							}
						})
						.catch((err)=>{
							this.loadingSpouse = false;
							console.error("clioGetRelatedContacts error->",err)
						})
			}catch(error){
				this.loadingSpouse = false;
				// console.log(" clioGetRelatedContacts error:",error)
			}
		},
		async clioClientLink(){
			try
			{
				const clioClientDetails = firebaseApp.functions('northamerica-northeast1').httpsCallable('clioApis-clioClient');
				const accessTokenClio=JSON.parse(localStorage.getItem('cliotoken'))
				// // console.log("acces TOKEN clioClientLink:",accessTokenClio.access_token)
				if(!accessTokenClio || !accessTokenClio.access_token){
					return console.error("No access token")
				} 
				if(!this.selectedMatterId){
					return console.error("No matter Id present")
				}
				let body={
					authorization: accessTokenClio.access_token,
					matterId: this.selectedMatterId
				}
				clioClientDetails(body)
					.then(clientResponse=>{
						if(clientResponse){
							if(clientResponse.data && clientResponse.data.data && JSON.parse(clientResponse.data.data)){
							// // console.log('clioClientLink resp->', JSON.parse(clientResponse.data.data))
								let clientDetail = JSON.parse(clientResponse.data.data);
								// relatedContactList.forEach(element => {
								// 	// this.partner2 = `${element.first_name} ${element.last_name}`
								// 	let contactText = `${element.first_name} ${element.last_name}`
								// 	this.secondaryContactList.push({value:`${element.id}`, text: contactText})
								// });
								this.selectedMatter = clientDetail;
							// // console.log("clioClientLink format response :", clientDetail)
							}
						}
					})
					.catch((err)=>{
						console.error("clioClientLink error->",err)
					})
			
			}catch(error){
				// console.log("error client:",error)
			}
		}
	},
	mounted(){
		this.clioMatterDetailsLink()
	}
};
</script>

<template>
	<b-modal
		id="clioImport"
		v-model="showClioModal"
		:title="title"
		@hide="hideClioModal"
		@ok="createAgreement"
	>
		<template #modal-footer="{ ok, cancel }">
			<!-- <b-button size="sm" @click="deleteEditingEvent()" data-testid="delete-editing-event">
				Delete
			</b-button> -->
			<b-button size="sm" variant="navy" @click="cancel()" data-testid="cancel-button"> Cancel </b-button>
			<b-button size="sm" variant="primary" @click="ok()" data-testid="save-button">
				Create agreement
			</b-button>
		</template>
			<b-form-group >
				<b-row>
					<b-col class="mb-3 my-3">Choose a matter to import:</b-col>
					<b-col>
						<b-spinner
						small 
						variant="primary"
						label="Loading..."
						type="grow"
						class="mb-3 my-3"
						v-if="loadingMatter"
						/>
						<b-form-select
							v-else
							class="mb-3 my-3"
							input-cols="4"
							id="clio-import"
							v-model="selectedMatterId"
							:options="matterList"
							data-testid="import-clio-file"
							v-on:change="onMatterSelect()"
							label="Choose a matter to import"
						/>
					</b-col>
				</b-row>
					<!-- <div>Choose a matter to import:</div>
					<b-spinner
						small 
						variant="primary"
						label="Loading..."
						type="grow"
						class="spinner-outer"
					/>
                    <b-form-select
						class="mb-3 my-3"
						input-cols="4"
						id="clio-import"
						v-model="selectedMatterId"
						:options="matterOptions"
						data-testid="import-clio-file"
						v-on:change="onMatterSelect()"
						label="Choose a matter to import"
					/> -->
					<b-row v-if="hasSecondaryContact">
						<b-col class="mb-3 my-3">Choose the spouse:</b-col>
						<b-col>
							<b-spinner
								small 
								variant="primary"
								label="Loading..."
								type="grow"
								class="mb-3 my-3"
								v-if="loadingSpouse"
							/>
							<b-form-select
								v-else
								class="mb-3 my-3"
								input-cols="4"
								id="secondary-contact"
								v-model="secondaryContactId"
								:options="secondaryContactList"
								data-testid="secondary-contact-clio"
							/>
						</b-col>
					</b-row>
					<b-row v-if="secondaryContactId && hasSecondaryContact">
						<b-col class="mb-3 my-3">Choose the party 1:</b-col>
						<b-col>
							<b-form-select
							class="mb-3 my-3"
							input-cols="4"
							id="primary-parent"
							v-model="primaryParent"
							:options="partnerOptions"
							data-testid="primary-parent-clio"
							/>
						</b-col>
					</b-row>
				<b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
			</b-form-group>
	</b-modal>
</template>